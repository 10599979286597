<template>
  <div class="image-grid">
    <div
      v-if="header || subheader || button?.content"
      class="image-grid__header"
    >
      <UiHeader
        class="image-grid__heading"
        :header="header"
        :subheader="subheader"
        :header-layout="headerLayout"
        :header-size="headerSize"
      />

      <ReadMore :button="button" :lang="lang" />
    </div>

    <div
      class="image-grid__gallery"
      :class="[wrapperItemsClass, wrapperGridClass]"
    >
      <template v-for="image in flatGallery" :key="image.id">
        <component
          :is="isLinkValid(image.properties.linkData) ? 't3-link' : 'div'"
          :to="image.properties.linkData"
          :class="isImageTall(image)"
          class="image-grid__image-wrapper"
        >
          <UiImg :image="image" class="image-grid__image" use-image-size />
        </component>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { T3File } from '@t3headless/nuxt-typo3'
import { computed } from 'vue'

import type { UiCeTextGalleryProps } from '~/types'
import { ReadMore, UiHeader, UiImg } from '~ui/components'
import useFlatGallery from '~ui/composables/useFlatGallery'
import { isLinkValid } from '~ui/helpers/linkUtils'

const props = defineProps<UiCeTextGalleryProps>()

const flatGallery = useFlatGallery(props)

const wrapperItemsClass = computed(() => {
  return flatGallery.value.length % 2 === 0
    ? 'image-grid__gallery--even'
    : 'image-grid__gallery--odd'
})
const wrapperGridClass = computed(() => {
  const imagesAmount = flatGallery.value.length

  if (imagesAmount === 4) {
    return 'image-grid__gallery--min'
  }

  if (imagesAmount % 4 === 0) {
    if (imagesAmount > 4 && imagesAmount % 3 === 1) {
      return 'image-grid__gallery--4-0x2'
    }
    if (imagesAmount > 4 && imagesAmount % 3 === 2) {
      return 'image-grid__gallery--4-0x1'
    }

    return 'image-grid__gallery--4-0'
  }
  if (imagesAmount % 4 === 2 && imagesAmount % 3 !== 0 && imagesAmount > 2) {
    return 'image-grid__gallery--4-2'
  }
  if (imagesAmount % 4 === 3 && imagesAmount > 3) {
    return 'image-grid__gallery--4-3'
  }
  if (imagesAmount % 3 === 0) {
    return 'image-grid__gallery--3-0'
  }
  if (imagesAmount % 3 === 2 && imagesAmount > 2) {
    return 'image-grid__gallery--3-2'
  }
  if (imagesAmount % 4 === 1) {
    return 'image-grid__gallery--4-3x'
  }

  return 'image-grid__gallery--auto'
})

const isImageTall = (image: T3File) => {
  const { height, width } = image.properties.dimensions
  return height > width / 2 ? 'image-grid__image-wrapper--tall' : ''
}
</script>

<style lang="scss">
.image-grid {
  outline: 1px solid color(alto);
  border-radius: 4px;

  &__header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: rem(16px);
    padding: rem(32px);
    padding-top: rem(48px);

    @include media-query(max sm) {
      flex-direction: column;
    }

    & .ui-header__title {
      margin: 0;
    }

    & .read-more {
      text-transform: uppercase;
      font-weight: 600;

      &::after {
        content: none;
      }
    }
  }

  $image-max-width: 250px;

  &__gallery {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr;
    width: 100%;
    overflow: hidden;
    gap: 1px;

    &--even {
      grid-template-columns: repeat(2, 1fr);
    }

    &--odd {
      grid-template-columns: repeat(2, 1fr);

      & > :where(*:last-child) {
        grid-column: span 2;
      }
    }

    @include media-query(calc($image-max-width * 3)) {
      &--auto {
        grid-template-columns: repeat(auto-fit, minmax($image-max-width, 1fr));
      }

      &--min {
        grid-template-columns: repeat(2, 1fr);
      }

      &--3-0,
      &--4-0 {
        grid-template-columns: repeat(3, 1fr);

        & > * {
          grid-column: span 1;
        }
      }

      &--3-2,
      &--4-0x1 {
        grid-template-columns: repeat(6, 1fr);

        & > * {
          grid-column: span 2;
        }

        & > :where(*:nth-last-child(-n + 2)) {
          grid-column: span 3;
        }
      }

      &--4-0x2 {
        grid-template-columns: repeat(6, 1fr);

        & > * {
          grid-column: span 2;
        }

        & > :where(*:nth-last-child(-n + 4)) {
          grid-column: span 3;
        }

        & > :where(*:nth-child(-n + 6)) {
          grid-column: span 2;
        }
      }

      &--4-2 {
        grid-template-columns: repeat(6, 1fr);

        & > * {
          grid-column: span 3;
        }

        & > :where(*:nth-child(-n + 6)) {
          grid-column: span 2;
        }
      }

      &--4-3 {
        grid-template-columns: repeat(6, 1fr);

        & > * {
          grid-column: span 3;
        }

        & > :where(*:nth-child(-n + 3)) {
          grid-column: span 2;
        }
      }

      &--4-3x {
        grid-template-columns: repeat(6, 1fr);

        & > * {
          grid-column: span 2;
        }

        & > :where(*:nth-last-child(-n + 4)) {
          grid-column: span 3;
        }

        & > :where(*:nth-child(-n + 6)) {
          grid-column: span 2;
        }
      }
    }

    @include media-query(calc($image-max-width * 4)) {
      &--4-0,
      &--4-0x1,
      &--4-0x2,
      &--min {
        grid-template-columns: repeat(4, 1fr);

        & > * {
          grid-column: span 1;
        }
      }

      &--4-2 {
        grid-template-columns: repeat(4, 1fr);

        & > * {
          grid-column: span 1;
        }

        & > :where(*:nth-last-child(-n + 2)) {
          grid-column: span 2;
        }
      }

      &--4-3 {
        grid-template-columns: repeat(12, 1fr);

        & > * {
          grid-column: span 3;
        }

        & > :where(*:nth-last-child(-n + 3)) {
          grid-column: span 4;
        }
      }

      &--4-3x {
        grid-template-columns: repeat(12, 1fr);

        & > * {
          grid-column: span 4;
        }

        & > :where(*:nth-child(-n + 4)) {
          grid-column: span 3;
        }
      }
    }

    @include media-query(max calc($image-max-width * 2)) {
      display: flex;
      flex-direction: column;
    }
  }

  &__image {
    &-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      outline: 1px solid color(alto);
      padding: rem(48px) rem(12px);

      --size: 0.675;
      height: 100px;

      &:where(&--tall) {
        height: 140px;
      }

      @include media-query(calc($image-max-width * 2)) {
        --size: 0.75;
        height: 180px;
      }
    }

    object-fit: contain;
    width: calc($image-max-width * var(--size));
    height: calc($image-max-width * var(--size) / 1.65);
  }

  &__header + &__gallery {
    border-top: 1px solid color(alto);
  }
}
</style>
